var mineralPatches = [
    {
    name:"Mineral Patch 1",
  array:[62158, 62159, 62160, 62161, 62162, 62163, 62672, 62673, 62674, 62675, 62676, 62677, 62678, 63186, 63187, 63188, 63189, 63190, 63191, 63192, 63700, 63701, 63702, 63703, 63704, 63705, 63706, 64214, 64215, 64216, 64217, 64218, 64219, 64220, 64728, 64729, 64730, 64731, 64732, 64733, 64734, 65242, 65243, 65244, 65245, 65246, 65247, 65248, 65756, 65757, 65758, 65759, 65760, 65761, 65762, 66270, 66271, 66272, 66273, 66274, 66275, 66276, 66784, 66785, 66786, 66787, 66788, 66789, 66790, 67298, 67299, 67300, 67301, 67302, 67303, 67304, 67812, 67813, 67814, 67815, 67816, 67817, 67818, 68326, 68327, 68328, 68329, 68330, 68331, 68332]
  },
  {
    name:"Mineral Patch 2",
    array:[77624, 77625, 77626, 77627, 78139, 78140, 78141, 78142, 78654, 78655, 78656, 78657, 79169, 79170, 79171, 79172, 79684, 79685, 79686, 79687, 80199, 80200, 80201, 80202, 80714, 80715, 80716, 80717]
  }
  
  ]

  export default mineralPatches