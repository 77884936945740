import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';

import * as THREE from 'three'
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { SelectionBox } from 'three/examples/jsm/interactive/SelectionBox.js';
import { SelectionHelper } from 'three/examples/jsm/interactive/SelectionHelper.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import gsap from 'gsap'
import { Power1 } from 'gsap'
import data from './place_names.json'
import vertexShader from './shaders/vertexShader.glsl.js'
import fragmentShader from './shaders/fragmentShader.glsl.js'
import atmosphereVertexShader from './shaders/atmosphereVertexShader.glsl.js'
import atmosphereFragmentShader from './shaders/atmosphereFragmentShader.glsl.js'
import notAvailable from './notAvailable'
import mineralPatches from './mineralPatches'







function App() {
  var camera, renderer, scene, scene2, scene3, io, tempInstance, controls, labelRenderer, mesh;
  var exited = false
  var lineGeometry;
  var atmosphere;
  var previousSize;

  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2(1, 1);

  var instanceId = 0
  var placeNameGroup = new THREE.Group()
  var placeLinesGroup = new THREE.Group()
  var indexer = 0

  scene = new THREE.Scene()
  scene2 = new THREE.Scene()
  scene3 = new THREE.Scene()

  var tempPtsCount = 1000
  var tempIndexer = 0
  let moved

  const color = new THREE.Color();
  const white = new THREE.Color(0xffffff)
  const brown = new THREE.Color(0x91591d)
  const red = new THREE.Color(0xff0000)

  let dummy = new THREE.Object3D();
  let m = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, transparent: true, opacity: 0 })
  var nodeDetailsObject;
  var bigPatchDetailsObject;

  var currentMatrix;


  useEffect(() => {
    init()

    window.addEventListener('resize', onWindowResize, false);

    document.getElementById("labelRenderer").addEventListener('mousedown', downListener)

    document.getElementById("labelRenderer").addEventListener('mousemove', moveListener)

    document.getElementById("labelRenderer").addEventListener('mouseup', upListener)



  }, [])


  function init() {
    var aspect;
    aspect = window.innerWidth / window.innerHeight
    camera = new THREE.PerspectiveCamera(45, aspect, 0.01, 1000);
    camera.position.set(0, 0, 20)



    //Create Label renderer
    labelRenderer = new CSS2DRenderer();
    labelRenderer.setSize(window.innerWidth, window.innerHeight);

    labelRenderer.domElement.className = "labelRenderer"
    labelRenderer.domElement.id = "labelRenderer"
    document.body.appendChild(labelRenderer.domElement);

    //Setup Renderer
    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.domElement.className = "renderer"
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.physicallyCorrectLights = true;
    renderer.toneMapping = THREE.ReinhardToneMapping
    labelRenderer.domElement.appendChild(renderer.domElement);

    //Create Stars
    const pointsGeometry = new THREE.BufferGeometry();
    const vertices = [];

    const sprite = new THREE.TextureLoader().load('assets/star.png');

    for (let i = 0; i < 1200; i++) {

      const x = 2000 * Math.random() - 1000;
      const y = 2000 * Math.random() - 1000;
      const z = 2000 * Math.random() - 1000;

      vertices.push(x, y, z);

    }

    pointsGeometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

    var pointsMaterial = new THREE.PointsMaterial({ size: 35, sizeAttenuation: true, map: sprite, alphaTest: 0.5, transparent: true });

    const particles = new THREE.Points(pointsGeometry, pointsMaterial);
    scene.add(particles);

    const lineMaterial = new THREE.LineBasicMaterial({
      color: 0xffffff
    });



    // //Setup Controls
    controls = new OrbitControls(camera, labelRenderer.domElement);
    controls.minDistance = 6
    controls.maxDistance = 20

    //Add Lighting
    var light1 = new THREE.AmbientLight(0xffffff, 1.0)
    scene.add(light1)


    var textureLoader = new THREE.TextureLoader()
    var diffuseMap = textureLoader.load("assets/2k_mars.jpeg", function (texture) {
      gsap.to(m, { opacity: 0.3, duration: 2 })
      gsap.to(material.uniforms.opacity, { value: 1, duration: 2 })
      gsap.to(atmosphereMaterial.uniforms.opacity, { value: 1, duration: 2 })

      scene.add(io);
      scene.add(tempInstance);
      data.map((v) => {
        var points = []
        points.push(latLonToCoord(v.lat, v.lon, 5));
        points.push(latLonToCoord(v.lat, v.lon, 5).multiplyScalar(1.1));
        lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
        const line = new THREE.Line(lineGeometry, lineMaterial);
        placeLinesGroup.add(line);
        const loader = new FontLoader();
        loader.load('fonts/font.json', function (font) {

          const color = 0xffffff;

          const matLite = new THREE.MeshBasicMaterial({
            color: color,
            side: THREE.DoubleSide
          });

          const message = v.name;

          const shapes = font.generateShapes(message, 100);

          const geometry = new THREE.ShapeGeometry(shapes);

          geometry.computeBoundingBox();

          const xMid = - 0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);

          geometry.translate(xMid, 0, 0);

          // make shape ( N.B. edge view not visible )

          const text = new THREE.Mesh(geometry, matLite);
          text.scale.setScalar(0.001)
          text.position.copy(latLonToCoord(v.lat, v.lon, 5).multiplyScalar(1.1))
          placeNameGroup.add(text);
        })

      })

      scene.add(placeNameGroup)
      scene.add(placeLinesGroup)

      document.getElementById("loading").style.display = "none"
    })
    // var normalMap = textureLoader.load("assets/mars_norm.png", function (texture) {

    // })

    var geometry = new THREE.SphereGeometry(4.999, 100, 100)
    // var material = new THREE.MeshPhongMaterial({ map: diffuseMap, normalMap, transparent: true, opacity: 0 })
    var material = new THREE.ShaderMaterial({
      transparent: true,
      vertexShader,
      fragmentShader,
      uniforms: {
        globeTexture: {
          value: diffuseMap,
        },
        opacity: {
          value: 0
        }
      }
    })

    mesh = new THREE.Mesh(geometry, material)

    scene.add(mesh)

    var atmosphereMaterial = new THREE.ShaderMaterial({
      vertexShader: atmosphereVertexShader,
      fragmentShader: atmosphereFragmentShader,
      transparent: true,
      uniforms: {
        opacity: {
          value: 0
        }
      },
      blending: THREE.AdditiveBlending,
      side: THREE.BackSide
    })

    // create atmosphere
    atmosphere = new THREE.Mesh(
      new THREE.SphereGeometry(4.999, 100, 100),
      atmosphereMaterial
    )

    atmosphere.scale.set(1.1, 1.1, 1.1)

    scene.add(atmosphere)

    const ptsCount = 154000;
    let g = new THREE.PlaneBufferGeometry(0.055, 0.032);
    io = new THREE.InstancedMesh(g, m, ptsCount);
    console.log(io)
    io.name = "io"


    let tempInstanceGeometry = new THREE.PlaneBufferGeometry(1, 1);
    let tempInstanceMaterial = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, transparent: true, opacity: 0.5, side: THREE.DoubleSide, opacity: 0 })
    tempInstance = new THREE.InstancedMesh(tempInstanceGeometry, tempInstanceMaterial, tempPtsCount);
    tempInstance.scale.setScalar(0.003)
    var nodeDetails = document.getElementById("nodeInfo")
    nodeDetailsObject = new CSS2DObject(nodeDetails);
    nodeDetailsObject.position.set(0, 0, 0)
    nodeDetailsObject.visible = false
    scene2.add(nodeDetailsObject)

    var bigPatchDetails = document.getElementById("bigPatchInfo")
    bigPatchDetailsObject = new CSS2DObject(bigPatchDetails);
    bigPatchDetailsObject.position.set(0, 0, 0)
    bigPatchDetailsObject.visible = false
    scene2.add(bigPatchDetailsObject)


    for (var i = -5; i < 5; i++) {
      for (var j = -5; j < 5; j++) {
        dummy.position.set(i * 1.04, j * 1.04, 0)
        dummy.updateMatrix();
        tempInstance.setMatrixAt(tempIndexer, dummy.matrix);
        var num = randomIntFromInterval(1, 10)
        if (num > 5) {
          tempInstance.setColorAt(tempIndexer, new THREE.Color(0xffffff));
        }
        else {
          tempInstance.setColorAt(tempIndexer, new THREE.Color(0xff0000));
        }
        tempIndexer++
      }
    }

    console.log(tempIndexer)

    for (let i = -87; i < 87; i += 0.4) {
      var alt = degsToRads(i)

      var c_alt = Math.cos(alt) * 5
      var s_alt = Math.sin(alt) * 5
      var positive = i < 0 ? i * -1 : i
      if (positive == 0) {
        positive = 1
      }

      var difference = 1;
      difference = convertRange(positive, { min: 1, max: 87 }, { min: 0.7, max: 10 })

      if (i > -25 && i < 25) {
        difference = easeInQuart(convertRange(difference, { min: 0.7, max: 10 }, { min: 0, max: 1 }))
        difference = convertRange(difference, { min: 0, max: 1 }, { min: 0.7, max: 10 })
        drawPatches(difference, c_alt, s_alt)
      }

      else if ((i < -25 && i > -40) || (i > 25 && i < 40)) {
        difference = easeInQuad(convertRange(difference, { min: 0.7, max: 10 }, { min: 0, max: 1 }))
        difference = convertRange(difference, { min: 0, max: 1 }, { min: 0.7, max: 10 })
        drawPatches(difference, c_alt, s_alt)

      }


      else if ((i < -40 && i > -45) || (i > 40 && i < 45)) {
        difference = easeInQuad2(convertRange(difference, { min: 0.7, max: 10 }, { min: 0, max: 1 }))
        difference = convertRange(difference, { min: 0, max: 1 }, { min: 0.7, max: 10 })
        drawPatches(difference, c_alt, s_alt)
      }

      else if ((i < -45 && i > -75) || (i > 45 && i < 75)) {
        difference = easeInExpo(convertRange(difference, { min: 0.7, max: 10 }, { min: 0, max: 1 }), 9)
        difference = convertRange(difference, { min: 0, max: 1 }, { min: 0.7, max: 10 })
        drawPatches(difference, c_alt, s_alt)
      }


      else if ((i < -75 && i > -80) || (i > 75 && i < 80)) {
        difference = easeInExpo(convertRange(difference, { min: 0.7, max: 10 }, { min: 0, max: 1 }), 9.2)
        difference = convertRange(difference, { min: 0, max: 1 }, { min: 0.7, max: 10 })
        drawPatches(difference, c_alt, s_alt)
      }


      else if ((i < -80 && i > -83) || (i > 80 && i < 83)) {
        difference = easeInExpo(convertRange(difference, { min: 0.7, max: 10 }, { min: 0, max: 1 }), 9.4)
        difference = convertRange(difference, { min: 0, max: 1 }, { min: 0.7, max: 10 })
        drawPatches(difference, c_alt, s_alt)
      }

      else if ((i < -83 && i > -85) || (i > 83 && i < 85)) {
        difference = easeInExpo(convertRange(difference, { min: 0.7, max: 10 }, { min: 0, max: 1 }), 9.7)
        difference = convertRange(difference, { min: 0, max: 1 }, { min: 0.7, max: 10 })
        drawPatches(difference, c_alt, s_alt)
      }

      else if ((i < -85 && i > -87) || (i > 85 && i < 87)) {
        difference = easeInExpo(convertRange(difference, { min: 0.7, max: 10 }, { min: 0, max: 1 }), 10.1)
        difference = convertRange(difference, { min: 0, max: 1 }, { min: 0.7, max: 10 })
        drawPatches(difference, c_alt, s_alt)
      }

    }
    render()
  }

  const saveTemplateAsFile = (filename, dataObjToWrite) => {
    const blob = new Blob([JSON.stringify(dataObjToWrite)], { type: "text/json" });
    const link = document.createElement("a");

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

    const evt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove()
  };


  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  function latLonToCoord(lat, lon, r) {
    var lonRad = -lon * (Math.PI / 180);
    var latRad = lat * (Math.PI / 180);
    return new THREE.Vector3(Math.cos(latRad) * Math.cos(lonRad) * r, Math.sin(latRad) * r, Math.cos(latRad) * Math.sin(lonRad) * r)
  }

  function radians_to_degrees(radians) {
    var pi = Math.PI;
    return radians * (180 / pi);
  }


  function zoomIn() {
    if (!exited) {
      if (camera.position.distanceTo(new THREE.Vector3(0, 0, 0)) > 8) {
        gsap.to(camera.position, { x: camera.position.x * 0.8, y: camera.position.y * 0.8, z: camera.position.z * 0.8, duration: 0.5, ease: Power1.easeIn })
      }
    }
  }


  function zoomOut() {
    if (!exited) {
      if (camera.position.distanceTo(new THREE.Vector3(0, 0, 0)) < 17) {
        gsap.to(camera.position, { x: camera.position.x * 1.2, y: camera.position.y * 1.2, z: camera.position.z * 1.2, duration: 0.5, ease: Power1.easeIn })
      }
    }
  }

  function exit() {
    placeNameGroup.traverse((v) => {
      v.visible = true
    })

    placeLinesGroup.traverse((v) => {
      v.visible = true
    })
    setTimeout(() => {
      controls.minDistance = 6
    }, 1000);

    nodeDetailsObject.visible = false
    gsap.to(camera.position, { x: camera.position.x * 2, y: camera.position.y * 2, z: camera.position.z * 2, duration: 1, ease: Power1.easeIn })
    gsap.to(tempInstance.material, {
      opacity: 0, duration: 1, ease: Power1.easeIn, onComplete: () => {
        gsap.to(tempInstance.position, {
          x: 0, y: 0, z: 0, duration: 1, ease: Power1.easeIn, onComplete: () => {
            tempInstance.lookAt(0, 0, 0)
            exited = false
          }
        })
      }
    })
    io.visible = true
    gsap.to(m, {
      opacity: 0.3, duration: 1, ease: Power1.easeIn
    })

    controls.enabled = true
    document.getElementById("exit").style.display = "none"
  }


  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
    labelRenderer.setSize(window.innerWidth, window.innerHeight);
  }


  function latLonFromVector3(position, sphereRadius) {
    var lat = Math.acos(position.y / sphereRadius); //theta
    var lon = Math.atan(position.x / position.z); //phi
    return [radians_to_degrees(lat), radians_to_degrees(lon)];
  }

  function degsToRads(deg) {
    return (deg * Math.PI) / 180.0
  }

  function onMouseClick() {
    raycaster.far = camera.position.distanceTo(new THREE.Vector3(0,0,0))
    raycaster.setFromCamera(mouse, camera);
    if (!exited) {
      const intersection = raycaster.intersectObject(io);
      const imageIntersection = raycaster.intersectObject(mesh);

      if (intersection.length > 0) {
        nodeDetailsObject.visible = false


        currentMatrix = new THREE.Matrix4()
        io.getMatrixAt(intersection[0].instanceId, currentMatrix)
        instanceId = intersection[0].instanceId
        io.getColorAt(instanceId, color);

        if (color.getHex() !== 9525533) {
          var position = new THREE.Vector3().setFromMatrixPosition(currentMatrix)
          bigPatchDetailsObject.position.copy(intersection[0].point)
          bigPatchDetailsObject.visible = true

          var bigPatchHeading = document.getElementById("bigPatchHeading")
          bigPatchHeading.innerHTML = "#" + intersection[0].instanceId

          var bigPatchNo = document.getElementById("bigPatchNo")
          bigPatchNo.innerHTML = "No: " + intersection[0].instanceId

          mineralPatches.map((v) => {
            if (v.array.includes(intersection[0].instanceId)) {
              bigPatchHeading.innerHTML = v.name
              bigPatchNo.innerHTML = v.name
            }
          })






          var bigPatchStatus = document.getElementById("bigPatchStatus")
          bigPatchStatus.innerHTML = color.getHex() == 16777215 ? "Available" : "Sold"
          bigPatchStatus.style.color = color.getHex() == 16777215 ? "#228B22" : "#ff0000"
      

        var uv = imageIntersection[0].uv
        uv.x = uv.x > 0 ? uv.x % 1 : 1 + (uv.x % 1);
        uv.y = uv.y > 0 ? uv.y % 1 : 1 + (uv.y % 1);
        var pixelXCoordinate = uv.x * 8192 - 0.5;
        var pixelYCoordinate = (1 - uv.y) * 4096 - 0.5;

        pixelXCoordinate = pixelXCoordinate < 0 ? 8192 - pixelXCoordinate : pixelXCoordinate;
        pixelYCoordinate = pixelYCoordinate < 0 ? 4096 - pixelYCoordinate : pixelYCoordinate;

        var x = Math.floor(pixelXCoordinate);
        var y = Math.floor(pixelYCoordinate);

        var thumbnailCanvas = document.getElementById("thumbnail")
        var thumbnailCanvasCtx = thumbnailCanvas.getContext("2d")
        thumbnailCanvasCtx.clearRect(0, 0, thumbnailCanvas.width, thumbnailCanvas.height);
        var image = new Image()
        image.addEventListener('load', function () {
          thumbnailCanvasCtx.drawImage(image, pixelXCoordinate, pixelYCoordinate, 10, 10, 0, 0, 100, 100);
        });
        image.src = "assets/2k_mars.jpeg"
      }






        // if (color.getHex() !== 9525533) {
        //     var position = new THREE.Vector3().setFromMatrixPosition(matrix)
        //     if (window.innerWidth < 600) {
        //         gsap.to(camera.position, { x: (position.x * 1.025), y: position.y * 1.025, z: position.z * 1.025, duration: 1, ease: Power1.easeIn })
        //     }
        //     else {
        //         gsap.to(camera.position, { x: position.x * 1.011, y: position.y * 1.011, z: position.z * 1.011, duration: 1, ease: Power1.easeIn })
        //     }
        //     gsap.to(m, { opacity: 0.05, duration: 1, ease: Power1.easeIn })
        //     gsap.to(tempInstance.position, {
        //         x: position.x * 1.0001, y: position.y * 1.0001, z: position.z * 1.0001, duration: 0, ease: Power1.easeIn, onComplete: () => {

        //         }
        //     })
        //     tempInstance.lookAt(0, 0, 0)
        //     gsap.to(tempInstance.material, { opacity: 0.5, duration: 1, ease: Power1.easeIn })

        //     document.getElementById("exit").style.display = "block"
        //     controls.minDistance = 0
        //     controls.enabled = false
        //     exited = true
        // }
      }

    }

    else {
      const intersection2 = raycaster.intersectObject(tempInstance);
      if (intersection2.length > 0) {


        const instanceId2 = intersection2[0].instanceId;
        tempInstance.getColorAt(instanceId2, color);
        placeNameGroup.traverse(function (child) {
          if (child instanceof THREE.Mesh) {
            child.visible = true;
          }
        });
        var matrix = new THREE.Matrix4()
        io.getMatrixAt(instanceId, matrix)
        var position = new THREE.Vector3().setFromMatrixPosition(matrix)
        var position2 = new THREE.Vector3((0.055 / 1000 * (instanceId2 + 0.5)) + position.x - (0.055 / 2), (0.032 / 2) + position.y - (0.032 / 2), position.z)
        if (window.innerWidth > 600) {
          var latElement = document.getElementById("lat")
          var lonElement = document.getElementById("lon")
          var soldElement = document.getElementById("sold")
          nodeDetailsObject.visible = true
          nodeDetailsObject.position.copy(intersection2[0].point)
          latElement.innerHTML = `<b>Lat:</b> ${latLonFromVector3(position2, 5)[0]}`
          lonElement.innerHTML = `<b>Lat:</b> ${latLonFromVector3(position2, 5)[1]}`
          soldElement.innerHTML = `<b>Status:</b> ${color.equals(new THREE.Color(0xffffff)) ? "Available" : "Sold"}`
        }
        else {
          var latElement = document.getElementById("latMobile")
          var lonElement = document.getElementById("lonMobile")
          var soldElement = document.getElementById("soldMobile")
          document.getElementById("nodeInfoMobile").style.display = "block"
          latElement.innerHTML = `<b>Lat:</b> ${latLonFromVector3(position2, 5)[0]}`
          lonElement.innerHTML = `<b>Lat:</b> ${latLonFromVector3(position2, 5)[1]}`
          soldElement.innerHTML = `<b>Status:</b> ${color.equals(new THREE.Color(0xffffff)) ? "Available" : "Sold"}`
        }
      }
    }

  }

  function downListenerMobile(e) {
    if (e.target.id == "closeMobile") {
      setTimeout(() => {
        document.getElementById("nodeInfoMobile").style.display = "none"
      }, 300);
    }
  }

  function downListener(e) {
    if (e.target.id == "close") {
      moved = true
      nodeDetailsObject.visible = false
    }
    else if (e.target.id == "closeBigPatch") {
      moved = true
      bigPatchDetailsObject.visible = false
      placeNameGroup.traverse((v) => {
        v.visible = true
      })

      placeLinesGroup.traverse((v) => {
        v.visible = true
      })
    }
    else if (e.target.id == "closeBigPatchZoom" || e.target.id == "zoomicon") {
      moved = true
      bigPatchDetailsObject.visible = false
      placeNameGroup.traverse((v) => {
        v.visible = false
      })

      placeLinesGroup.traverse((v) => {
        v.visible = false
      })
      openSmallPatches()
    }
    else {
      moved = false
    }
  }





  function upListener() {
    if (!moved) {
      onMouseClick()
    }
  }

  function openSmallPatches() {
    if (color.getHex() !== 9525533) {
      var position = new THREE.Vector3().setFromMatrixPosition(currentMatrix)
      if (window.innerWidth < 600) {
        gsap.to(camera.position, { x: (position.x * 1.025), y: position.y * 1.025, z: position.z * 1.025, duration: 1, ease: Power1.easeIn })
      }
      else {
        gsap.to(camera.position, { x: position.x * 1.011, y: position.y * 1.011, z: position.z * 1.011, duration: 1, ease: Power1.easeIn })
      }
      gsap.to(m, { opacity: 0.05, duration: 1, ease: Power1.easeIn })
      gsap.to(tempInstance.position, {
        x: position.x * 1.0001, y: position.y * 1.0001, z: position.z * 1.0001, duration: 0, ease: Power1.easeIn, onComplete: () => {

        }
      })
      tempInstance.lookAt(0, 0, 0)
      gsap.to(tempInstance.material, { opacity: 0.5, duration: 1, ease: Power1.easeIn })

      document.getElementById("exit").style.display = "block"
      controls.minDistance = 0
      controls.enabled = false
      exited = true
    }
  }

  function moveListener(event) {
    moved = true

    event.preventDefault();

    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
  }

  function convertRange(value, oldRange, newRange) {
    return ((value - oldRange.min) * (newRange.max - newRange.min)) / (oldRange.max - oldRange.min) + newRange.min;
  }

  function easeInQuart(x) {
    return x === 0 ? 0 : Math.pow(2, 15 * x - 15);
  }

  function easeInQuad(x) {
    return x * x * x * x * x;
  }

  function easeInQuad2(x) {
    return x * x * x * x * x * (x * 1.6);
  }

  function easeInExpo(x, num) {
    return x === 0 ? 0 : Math.pow(2, num * x - 10);
  }

  function drawPatches(difference, c_alt, s_alt) {
    for (let j = 0; j < 360; j += difference) {
      if (j < 360) {
        var azi = degsToRads(j)
        var c_azi = Math.cos(azi)
        var s_azi = Math.sin(azi)
        var position = new THREE.Vector3(c_azi * c_alt, s_alt, s_azi * c_alt)
        dummy.position.set(position.x, position.y, position.z)
        dummy.lookAt(0, 0, 0)
        dummy.updateMatrix();
        if (!notAvailable.includes(indexer)) {
          io.setMatrixAt(indexer, dummy.matrix);
       
        var num = randomIntFromInterval(1, 10)

        if (num > 3 && num <= 4) {
          io.setColorAt(indexer, red);
        }
        else {
          io.setColorAt(indexer, white);
        }
        mineralPatches.map((v) => {
          if (v.array.includes(indexer)) {
            io.setColorAt(indexer, new THREE.Color(0x008000));
          }
        })
      }

        indexer += 1
      }

    }
  }

  function showHidePatch() {
    io.visible = !io.visible
  }

  function easeOutExpo(x) {
    return x * x;
  }




  //Render Scene
  function render() {
    requestAnimationFrame(render)
    renderer.render(scene, camera)
    controls.target.set(0, 0, 0)
    controls.update()
    labelRenderer.render(scene2, camera)
    placeNameGroup.traverse((v) => {
      if (v.isMesh) {
        v.lookAt(camera.position)
      }
    })
    var size = 6 / camera.position.distanceTo(new THREE.Vector3(0, 0, 0))
    size = easeOutExpo(size)
    size = convertRange(size, { min: 0, max: 1 }, { min: 1, max: 2 })
    if (size !== previousSize) {
      atmosphere.scale.setScalar(size)
      previousSize = size
    }

  }

  function turnOnSelectionMode() {
    const selectionBox = new SelectionBox(camera, scene);
    const helper = new SelectionHelper(selectionBox, renderer, 'selectBox');
    controls.enabled = false
    console.log("hello")

    document.addEventListener('pointerdown', function (event) {
      selectionBox.instances = {}
      selectionBox.startPoint.set(
        (event.clientX / window.innerWidth) * 2 - 1,
        - (event.clientY / window.innerHeight) * 2 + 1,
        0.5);

    });

    document.addEventListener('pointermove', function (event) {

      if (helper.isDown) {



        selectionBox.endPoint.set(
          (event.clientX / window.innerWidth) * 2 - 1,
          - (event.clientY / window.innerHeight) * 2 + 1,
          0.5);



      }

    });

    document.addEventListener('pointerup', function (event) {

      selectionBox.endPoint.set(
        (event.clientX / window.innerWidth) * 2 - 1,
        - (event.clientY / window.innerHeight) * 2 + 1,
        0.5);
      const allSelected = selectionBox.select();
      // const allSelected = selectionBox.instaßßnces;
      var selected = []
      Object.values(selectionBox.instances)[0].map((v) => {
        var currentMatric = new THREE.Matrix4()
        io.getMatrixAt(v, currentMatric)
        var position = new THREE.Vector3().setFromMatrixPosition(currentMatric)
        if (camera.position.distanceTo(position) < camera.position.distanceTo(new THREE.Vector3(0,0,0))) {
          io.setColorAt(v, new THREE.Color(0xffff00))
          io.instanceColor.needsUpdate = true
          selected.push(v)
        }
      })
      console.log(selected)
    });

  }

  function goToPatch(){
    var input = document.getElementById("search").value
    if(input!="" && input<=153929 && !notAvailable.includes(parseInt(input))){
    currentMatrix = new THREE.Matrix4()
    io.getMatrixAt(input, currentMatrix)
    var position = new THREE.Vector3().setFromMatrixPosition(currentMatrix)

    bigPatchDetailsObject.visible = false
    gsap.to(camera.position, { x: (position.x * 2), y: position.y * 2, z: position.z * 2, duration: 1 ,onComplete:()=>{
      mouse.x = ((window.innerWidth/2) / window.innerWidth) * 2 - 1;
      mouse.y = - ((window.innerHeight/2) / window.innerHeight) * 2 + 1;
      raycaster.far = camera.position.distanceTo(new THREE.Vector3(0,0,0))
      raycaster.setFromCamera(mouse, camera);
      const imageIntersection = raycaster.intersectObject(mesh);

      bigPatchDetailsObject.position.copy(position)
      bigPatchDetailsObject.visible = true

      var bigPatchHeading = document.getElementById("bigPatchHeading")
      bigPatchHeading.innerHTML = "#" + input

      var bigPatchNo = document.getElementById("bigPatchNo")
      bigPatchNo.innerHTML = "No: " + input

      mineralPatches.map((v) => {
        if (v.array.includes(parseInt(input))) {
          bigPatchHeading.innerHTML = v.name
          bigPatchNo.innerHTML = v.name
        }
      })






      var bigPatchStatus = document.getElementById("bigPatchStatus")
      bigPatchStatus.innerHTML = color.getHex() == 16777215 ? "Available" : "Sold"
      bigPatchStatus.style.color = color.getHex() == 16777215 ? "#228B22" : "#ff0000"
  

    var uv = imageIntersection[0].uv
    uv.x = uv.x > 0 ? uv.x % 1 : 1 + (uv.x % 1);
    uv.y = uv.y > 0 ? uv.y % 1 : 1 + (uv.y % 1);
    var pixelXCoordinate = uv.x * 8192 - 0.5;
    var pixelYCoordinate = (1 - uv.y) * 4096 - 0.5;

    pixelXCoordinate = pixelXCoordinate < 0 ? 8192 - pixelXCoordinate : pixelXCoordinate;
    pixelYCoordinate = pixelYCoordinate < 0 ? 4096 - pixelYCoordinate : pixelYCoordinate;

    var x = Math.floor(pixelXCoordinate);
    var y = Math.floor(pixelYCoordinate);

    var thumbnailCanvas = document.getElementById("thumbnail")
    var thumbnailCanvasCtx = thumbnailCanvas.getContext("2d")
    thumbnailCanvasCtx.clearRect(0, 0, thumbnailCanvas.width, thumbnailCanvas.height);
    var image = new Image()
    image.addEventListener('load', function () {
      thumbnailCanvasCtx.drawImage(image, pixelXCoordinate, pixelYCoordinate, 10, 10, 0, 0, 100, 100);
    });
    image.src = "assets/2k_mars.jpeg"
    }})
  }
  }




  return (
    <div>

      <div className='search'>
      
        <input type='number' min="0" max="153929" id="search" placeholder='Enter Patch ID'></input>
      
        <button onClick={goToPatch}>Go to</button>
      
      </div>

      <div className="node-info" id="nodeInfo">
        <div className='crossposition'><img src="assets/crossImg.webp" className="cross" id="close" /></div>
        <h2>Info</h2>
        <p id="lat"></p>
        <p id="lon"></p>
        <p id="sold"></p>


      </div>

      <div className="node-info-mobile" id="nodeInfoMobile" onMouseDown={downListenerMobile}>
        <div className='crossposition'><img src="assets/crossImg.webp" className="cross" id="closeMobile" /></div>
        <h2>Info</h2>
        <p id="latMobile"></p>
        <p id="lonMobile"></p>
        <p id="soldMobile"></p>
      </div>

      <div className='big-patch-info' id="bigPatchInfo">

        <div className='row'>
          <div className='canvasDiv'>
            <canvas id="thumbnail" width={100} height={100} />
          </div>
          <div>

            <h2 id="bigPatchHeading"></h2>
            <p id="bigPatchNo"></p>
            <p id="bigPatchPrice">Price: 200$</p>

            <p id="bigPatchStatus"></p>


          </div>
          <div id='crossId'><img src="assets/crossImg.webp" className="cross" id="closeBigPatch" /></div>
        </div>
        <div className='border'>

          <div id="closeBigPatchZoom">
            <img id='zoomicon' src='assets/zoom.png'></img>
            Zoom</div>
        </div>

      </div>

      <p className="loading" id="loading">Loading...</p>

      <img src='assets/filter-crss.png' className="button-3" id="exit" onClick={exit}></img>
      <div className="controls">
        <div className="zoom" id="zoomIn" onClick={zoomIn}>+</div>
        <div className="zoom" id="zoomOut" onClick={zoomOut}>-</div>
        <div className="zoom" onClick={showHidePatch} id="show"><img className="eye" src="https://www.freeiconspng.com/thumbs/eye-icon/eyeball-icon-png-eye-icon-1.png" /></div>
        <button className='multiSelect' onClick={turnOnSelectionMode}>Multi Select</button>
      </div>


    </div>
  );
}

export default App;
