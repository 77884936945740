var fragmentShader = `
uniform sampler2D globeTexture;
uniform float opacity;
varying vec2 vertexUV; // [0, 0.24]
varying vec3 vertexNormal;

void main() {
  float intensity = 1.05 - dot(vertexNormal, vec3(0.0, 0.0, 1.0 ));
  vec3 atmosphere = vec3(1, 0.819, 0.619) * pow(intensity, 1.5);
  vec4 tex = texture2D( globeTexture, vertexUV );

  
  gl_FragColor = vec4((atmosphere + tex.xyz),opacity); 
}`

export default fragmentShader